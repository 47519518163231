<template>
  <div class="accounts">
    <div class="accounts-title">渠道绑定</div>
    <div class="account-box" v-for="item in list" :key="item.id" @click="setCollapse(item.id)">
      <div class="atop">
        <div class="atop-left">
          <img :src="item.icon" alt="" class="atop-left-img" />
          <div class="atop-left-title">{{ item.name }}({{ item.kocPromotion.length }})</div>
        </div>
        <van-button size="mini" icon="plus" @click.stop="add(item)" class="atop-right">绑定账号</van-button>
      </div>
      <van-collapse accordion v-model="activeNames" v-show="item.kocPromotion.length > 0">
        <van-collapse-item @click.stop :ref="'kzmb' + item.id" :title="activeNames == item.id ? '收起账号列表' : '展开账号列表'" :name="item.id">
          <div class="account-list">
            <template v-for="child in item.kocPromotion" :key="child.value">
              <div class="account-list-row" v-if="child.value != 0">
                <div class="alr-box">
                  <div class="alr-top">
                    <div class="alr-top-name">{{ child.platform_name }} <span class="alr-top-close" v-if="child.status == 20">(已关闭)</span></div>
                    <div class="alr-top-btn" @click.stop="edit(item, child.id)">编辑</div>
                  </div>
                  <div class="alr-btm">
                    <div class="alr-btm-t">
                      <div class="alr-btm-t-row text-ellipsis">粉丝量：{{ child.fans_num }}</div>
                      <div class="alr-btm-t-row text-ellipsis">ID：{{ child.platform_code }}</div>
                      <div class="alr-btm-t-row text-ellipsis">账号分类：{{ child.type_name }}</div>
                      <div class="alr-btm-t-row text-ellipsis">创建日期：{{ child.create_time ? child.create_time.substr(0, 10) : '' }}</div>
                    </div>
                    <div class="alr-btm-b text-ellipsis van-hairline--top" v-if="child.user_link != ''" @click="onCopy(child.user_link)">
                      <i class="font_family icon-fuzhipeizhi"></i>点击复制地址：{{ child.user_link }}
                    </div>
                  </div>
                </div>
              </div>
            </template>
          </div>
        </van-collapse-item>
      </van-collapse>
    </div>
  </div>
</template>

<script>
import { getPromotionPlatform } from '@/api/public'
import { Toast } from 'vant'

export default {
  name: 'bindingChannel',

  data() {
    return {
      list: [],
      activeNames: '',
      show: false
    }
  },
  async activated() {
    this.getList()
  },
  methods: {
    async getList() {
      this.list = await getPromotionPlatform()
    },
    onCopy(text) {
      this.$copyText(text)
        .then(() => {
          Toast('已成功复制到剪切板')
        })
        .catch(() => {
          Toast('复制失败')
        })
    },
    add(item) {
      this.$router.push({ path: '/addAccount', query: { promotionPlatformId: item.id, name: item.name, type: 'add', linkType: item.linkType, userInfoLink: item.userInfoLink } })
    },
    edit(item, platformId) {
      this.$router.push({ path: '/addAccount', query: { promotionPlatformId: item.id, platformId, name: item.name, type: 'edit', linkType: item.linkType, userInfoLink: item.userInfoLink } })
    },
    // 点击列表即可展示或收起
    setCollapse(id) {
      if (this.activeNames == id) {
        this.activeNames = ''
      } else {
        this.activeNames = id
      }
    }
  }
}
</script>

<style lang="less" scoped>
.accounts {
  padding-top: 102px;
  .accounts-title {
    position: fixed;
    top: 0px;
    left: 0px;
    padding: 20px 0px;
    width: 100%;
    z-index: 9;
    margin-bottom: 20px;
    border-radius: 10px;
    background: white;
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  }
  .account-box {
    overflow: hidden;
    margin: 0px 20px 20px;
    background: white;
    border-radius: 16px;
    .atop {
      padding: 30px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      .atop-left {
        display: flex;
        justify-content: space-between;
        align-items: center;
        .atop-left-img {
          width: 90px;
          height: 90px;
          border-radius: 100px;
        }
        .atop-left-title {
          margin-left: 20px;
          font-size: 30px;
          font-weight: bold;
          color: #333;
        }
      }
      .atop-right {
        border-radius: 10px;
        padding: 10px 20px;
        font-size: 22px !important;
        color: white;
        background-image: linear-gradient(to right, #ff9f71, #ff9567, #ff8b5d, #fe8154, #fe764b);
        /deep/ .van-button__icon {
          font-size: 20px !important;
        }
      }
    }
    .account-list {
      padding: 0px 20px 20px;
      background: white;
      .account-list-row {
        padding: 20px 20px 10px;
        margin-bottom: 20px;
        border-radius: 14px;
        // background: white;
        background: #f8f8f8;
        .alr-box {
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          .alr-top {
            display: flex;
            justify-content: space-between;
            align-items: center;
            font-size: 24px;
            padding-bottom: 20px;
            .alr-top-name {
              color: #666;
            }
            .alr-top-close {
              color: red;
              padding: 2px 12px;
              border-radius: 12px;
              margin-left: 10px;
            }
            .alr-top-btn {
              color: #fe8154;
              border: 1px solid #fe8154;
              padding: 2px 16px;
              border-radius: 12px;
            }
          }
          .alr-btm-t {
            display: flex;
            justify-content: space-between;
            flex-wrap: wrap;
            padding: 20px 0px 10px;

            .alr-btm-t-row {
              text-align: left;
              width: 50%;
            }
          }
          .alr-btm-b {
            padding-top: 10px;
            text-align: left;
            color: #fe8154;
            i {
              margin-right: 4px;
            }
          }
        }
      }
    }
  }
  .account-list > :nth-child(1) {
    border-top-left-radius: 0px !important;
    border-top-right-radius: 0px !important;
  }
  /deep/ .van-collapse-item__content {
    padding: 0px;
    background: #f7f7f7;
  }
  /deep/ .van-cell {
    padding-top: 8px;
    padding-bottom: 8px;
    color: #999999;
    font-size: 20px;
  }
}
/deep/ .van-collapse-item__title--expanded::after {
  display: none;
}
.icon-fuzhipeizhi {
  font-size: 30px;
}
</style>
